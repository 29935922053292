import {combineReducers} from 'redux';


const allReducers = combineReducers({
});

const rootReducer = (state, action) => {
    return allReducers(state, action)
};

export default rootReducer;
