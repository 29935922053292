import { applyMiddleware, createStore } from 'redux';
import rootReducer from './reducers/rootReducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import {loadState} from "./utils/localStorage";
import {development} from "./utils/common";

const middleware = [
    thunk
];

if (development){
    middleware.push(logger);
}

let persistedData = loadState();
export const store = createStore(rootReducer, persistedData, applyMiddleware(...middleware));