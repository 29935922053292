import React, {Component} from 'react';
import './App.css';
import Home from "./pages/home";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {throttle} from "throttle-debounce";
import Config from "./components/config";
import {Provider} from "react-redux";
import {store} from "./store";
import {persistState} from "./utils/localStorage";

store.subscribe(throttle(500, () => {
    persistState(store.getState());
}));

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <Config>
                        <Switch>
                            <Route exact path={"/"} component={Home}/>
                        </Switch>
                    </Config>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App;
