
export const development = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');

export const isOnTimeWindow = (start, end) => {
    const current = new Date();
    const hours = current.getHours();
    const minutes = current.getMinutes();
    const seconds = current.getSeconds();
    const time = ((hours * 60 * 60) + (minutes * 60) + seconds);
    return time >= start && time <= end;
};

export const isAvailable = (category) => {
    if(!category){
        return false
    }

    const isOnWindow = isOnTimeWindow(category.orderWindowStartTime, category.orderWindowEndTime);
    const isDeliveryTypeToday = category.deliveryTypeToday && !category.deliveryTypeTomorrow;
    if (isDeliveryTypeToday && !isOnWindow) {
        return false;
    }

    const isOnDeliveryWindow =  isOnTimeWindow(category.deliveryWindowStartTimeSeconds, category.deliveryWindowEndTimeSeconds);
    if (isDeliveryTypeToday && !isOnDeliveryWindow) {
        const current = new Date();
        const startHour = category.deliveryWindowStartTime.split(":")[0];
        return current.getHours() < startHour;
    }

    return !(category.deliveryTypeTomorrow && !category.deliveryTypeToday && !isOnWindow);
};

export const buildHoursList = (category, deliveryType) => {
    const startHour = category.deliveryWindowStartTime.split(":")[0];
    const endHour = category.deliveryWindowEndTime.split(":")[0];
    const currentHour = new Date().getHours();
    const hourvalues = [];
    for (let i = 0; i <= 24; i++) {
        if (i >= parseInt(startHour, 10) && i <= parseInt(endHour, 10)) {
            let add = deliveryType === "tomorrow" || i >= currentHour;

            if (add) {
                let string = "";
                if (i > 12) {
                    string = (i - 12) + " PM";
                } else {
                    string = i + " AM";
                }
                hourvalues.push({
                    hour: i,
                    string: string
                });
            }
        }
    }

    return {
        hoursValues: hourvalues,
        hours: (hourvalues.length > 0 && hourvalues[0].hour) || "",
    };
};

export const toFixed = (num, fixed) => {
    fixed = fixed || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(num * fixed) / fixed;
};
