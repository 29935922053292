import React, {Component} from 'react';
import axios from "axios";
import qs from "query-string";
import MakeAnOrder from "./makeAnOrder";
import {isAvailable, isOnTimeWindow, toFixed} from "../utils/common";

class Home extends Component {
    constructor(props){
        super(props);
        const { location:{search} } = this.props;
        const qstring = qs.parse(search);
        const reservationId = qstring.id;

        const initialState = {
            reservationRoomId: null,
            reservationRoomList: [],
            errorMessage: null,
            availablesCategories: null,
            notAvailablesCategories: null,
            categoryAvailableList: null,
            showItems: true,
            itemList: null,
            cart: null,
            total: 0,
            subTotal: 0,
            tax: 0,
            showToday: true,
            showTomorrow: true,
            deliveryType: null,
            reservationRoom: null,
            categoryId: null,
            isReservationCheckedIn: null,
            daySelection: false,
            deliveryDay: "",
            categorySelected: null,
            firstArrivalDate: null,
            roomTaxRate: null,
            salesTaxRate: null,
            instructions: null,
            haveReservation: false
        };

        if(!!reservationId){
            const initialData = this.loadInitData(reservationId);
            this.state={
                ...initialState,
                ...initialData,
            }
        } else {
            this.state={
                ...initialState,
                errorMessage: 'Error loading the categories',
            }
        }
    }

    loadReservationData = async (reservationId) => {
        // TODO: errorMessages are being ovewritten here

        let response = await axios.get("/ws/guestshopapi/listReservationRoomByReservation?reservationId=" + reservationId);
        if(!!response){
            this.setState({
                reservationRoomList: response.data,
                reservationRoomId: response.data[0].reservationRoomId,
            });
        } else {
            this.setState({errorMessage: 'Error loading the reservation data'});
        }

        response = await axios.get("/ws/guestshopapi/isReservationCheckedIn?reservationId=" + reservationId);
        if(!!response){
            this.setState({isReservationCheckedIn: response.data});
        } else {
            this.setState({errorMessage: 'Error loading the reservation status'});
        }

        response = await axios.get("/ws/guestshopapi/getFirstArrivalDate?reservationId=" + reservationId);
        if(!!response){
            this.setState({firstArrivalDate: response.data});
        } else {
            this.setState({errorMessage: 'Error loading the arrival date'});
        }
    };

    loadInitData = async (reservationId) => {
        const response = await axios.get("/ws/guestshopapi/categoires?reservationId=" + reservationId); // TODO: api name typo
        const guestShopResponse = !!response && response.data;
        if (!!guestShopResponse) {
            this.setState({
                haveReservation: true,
                errorMessage: guestShopResponse.errorMessage,
                availablesCategories: guestShopResponse.availablesCategories,
                notAvailablesCategories: guestShopResponse.notAvailablesCategories,
                roomTaxRate: guestShopResponse.roomTaxRate,
                salesTaxRate: guestShopResponse.salesTaxRate,
            });

            if (this.state.errorMessage == null) {
                this.loadReservationData(reservationId);
            }
        } else {
            this.setState({errorMessage: 'Error loading the categories'});
        }
    };

    renderErrorMessage = (errorMessage) => <div><h2>{errorMessage}</h2></div>;

    buildItemList = (items) => {
        const {roomTaxRate, salesTaxRate} = this.state;
        !!items && Array.isArray(items) && items.forEach(curItem => {
            let taxAmount = 0;
            if (curItem.roomTax && roomTaxRate != null && curItem) {
                taxAmount += parseFloat(curItem.price) * parseFloat(roomTaxRate);
            }
            if (curItem.salesTax && salesTaxRate != null && curItem) {
                taxAmount += parseFloat(curItem.price) * parseFloat(salesTaxRate);
            }
            curItem.tax = toFixed(taxAmount, 2);
            curItem.priceWithOutTax = curItem.price;
            curItem.price += taxAmount;
            curItem.price = toFixed(curItem.price, 2);
        });

        return items;
    };

    onCategoryClicked = (category) => {
        const itemList = this.buildItemList(category.itemList);
        const cart = [];
        itemList.forEach((item, i) => {
            cart[i] = {
                id: item.id,
                price: item.price,
                quantity: 0,
                tax: item.tax,
                priceWithOutTax: item.priceWithOutTax
            };
        });

        const isOnOrderWindow = isOnTimeWindow(category.orderWindowStartTime, category.orderWindowEndTime);
        const isOnDeliveryWindow = isOnTimeWindow(category.deliveryWindowStartTimeSeconds, category.deliveryWindowEndTimeSeconds);

        let deliveryType = "today";
        let deliveryDay = this.state.deliveryDay;
        if (category.deliveryTypeToday && !category.deliveryTypeTomorrow) {
            deliveryDay = "Today";
            deliveryType = "today";
        }
        if (!category.deliveryTypeToday && category.deliveryTypeTomorrow) {
            deliveryDay = "Tomorrow";
            deliveryType = "tomorrow";
        }

        let showToday = category.deliveryTypeToday && isOnOrderWindow;
        if (deliveryType === "today") {
            if (!isOnOrderWindow) {
                showToday = false;
                deliveryType = "tomorrow";
            } else {
                if (isOnDeliveryWindow) {
                    deliveryType = "today";
                } else {
                    showToday = false;
                    deliveryType = "tomorrow";
                }
            }
        }

        if (!this.state.isReservationCheckedIn) {
            deliveryDay = "Delivery date: Arrival date";
        }


        setTimeout(function () {
            if (document.getElementById('items') != null) {
                document.getElementById('items').classList.add("slidein");
            }
        }, 100);

        this.setState({
            categorySelected: category,
            instructions: category.instructions,
            showItems: false,
            categoryId: category.id,
            itemList: itemList,
            showTomorrow: category.deliveryTypeTomorrow,
            daySelection: category.deliveryTypeToday && category.deliveryTypeTomorrow,
            cart: cart,
            showToday: showToday,
            deliveryType: deliveryType,
            deliveryDay: deliveryDay,
        });
    };

    isOnDelivery = (category) => {
        const isOnWindow = isOnTimeWindow(category.deliveryWindowStartTimeSeconds, category.deliveryWindowEndTimeSeconds);
        if (isOnWindow){
            return true;
        } else{
            const current = new Date();
            const startHour = category.deliveryWindowStartTime.split(":")[0];
            return current.getHours() < startHour;
        }
    };

    renderCategories = () => {
        const {availablesCategories, notAvailablesCategories} = this.state;

        const cs = availablesCategories.map((category, idx) => {
            return <div key={idx} className={"option"}>
                <button className={'optionButton'} onClick={() => this.onCategoryClicked(category)}> {category.name} </button>
                {!isAvailable(category) &&
                    <div className="not-available">
                        {!this.isOnDelivery(category) ? (
                            <div>Not available to be delivered until {category.deliveryWindowStartTimeAsString}</div>
                        ):(
                            <div>Not available until {category.orderWindowStartTimeAsStirng}</div>
                        )}
                    </div>
                }
            </div>
        });

        return <React.Fragment>
            <h2>Make an order</h2>
            <div className="answer"> {cs} </div>
            {notAvailablesCategories !== null && notAvailablesCategories.length > 0 &&
                <div>
                    <h3>These menus available upon checkin:</h3>
                    <div className="answer">
                        {notAvailablesCategories.map((category, idx) => <button className={'option-not-available optionButton'} key={idx}>{category.name}</button>) }
                    </div>
                </div>
            }
        </React.Fragment>
    };

    render() {
        const {showItems, errorMessage, availablesCategories } = this.state;

        return (
            <React.Fragment>
                <div className="content">
                    {errorMessage !== null ? (
                        this.renderErrorMessage(errorMessage)
                    ) : (
                        availablesCategories === null ? (
                            <div><img alt="loading" src="./assets/loading.gif"/></div>
                        ) : (
                            !!showItems ? (
                                this.renderCategories()
                            ) : (
                                <MakeAnOrder state={this.state}/>
                            )
                        )
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default Home;
