import axios from 'axios';

export function axiosConfig() {
    axios.interceptors.request.use(function (config) {
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Content-Type'] = 'application/json';
        return config;
    });

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (!!error && !!error.response && error.response.status === 401) {
            // Handle unauthorized
            return error.response;
        }
        return error;
    });
}
