import React, {Component} from 'react';
import axios from "axios";
import {buildHoursList, isAvailable, toFixed} from "../utils/common";

class MakeAnOrder extends Component {
    constructor(props){
        super(props);
        const {
            categorySelected,
            firstArrivalDate,
            deliveryType,
            categoryId,
            reservationRoomId,
            reservationRoomList,
            roomTaxRate,
            salesTaxRate,
            itemList,
            cart,
            instructions,
        } = props.state;

        this.state = {
            orderComplete: false,
            cart,
            total: 0,
            subTotal: 0,
            tax: 0,
            errorOnSave: null,
            categorySelected,
            firstArrivalDate,
            deliveryType,
            categoryId,
            reservationRoomId,
            roomTaxRate,
            salesTaxRate,
            itemList,
            reservationRoomList,
            instructions,
            minutesValues: ["0", "15", "30", "45"],
            minutes: "0",
            ...buildHoursList(categorySelected, categorySelected.deliveryType),
        };
    }

    returnToShop = () => window.location.reload();

    calculateTotal = () => {
        const {cart} = this.state;

        let total = 0;
        let subTotal = 0;
        let tax = 0;

        cart.forEach(curCart => {
            tax += curCart.tax * curCart.quantity;
            subTotal += curCart.priceWithOutTax * curCart.quantity;
            total += curCart.price * curCart.quantity;
        });

        this.setState({total, subTotal, tax});
    };

    removeItemFromCart = (index) => {
        const {cart} = this.state;
        if (cart[index].quantity - 1 >= 0) {
            cart[index].quantity --;

            const updatedCartIdx = {...cart[index]};
            const updatedCart = cart;
            updatedCart[index] = updatedCartIdx;

            this.setState({cart: updatedCart});
        }
        this.calculateTotal();
    };

    addItemToCart = (index) => {
        const {cart} = this.state;
        cart[index].quantity++;

        const updatedCartIdx = {...cart[index]};
        const updatedCart = cart;
        updatedCart[index] = updatedCartIdx;

        this.setState({cart: updatedCart});
        this.calculateTotal();
    };

    pad = (value) => {
        while (value.length < (2)) {
            value = "0" + value;
        }
        return value;
    };

    cartHasAnyItem = () => {
        const {cart} = this.state;
        let hasAnyItem = false;
        !!cart && Array.isArray(cart) && cart.forEach(curCart => {
            if (curCart.quantity > 0) {
                hasAnyItem = true;
            }
        });
        return hasAnyItem;
    };

    isRoomSelected = () =>  this.state.reservationRoomId !== null;

    saveGuestOrder = async () => {
        if (!this.cartHasAnyItem()) {
            alert("You need to select any item");
            return;
        }
        if (!this.isRoomSelected()) {
            alert("Room is mandatory");
            return;
        }

        const {
            categorySelected, firstArrivalDate, deliveryType, hours, minutes, cart,
            categoryId, reservationRoomId, roomTaxRate, salesTaxRate
        } = this.state;

        let current = new Date();
        if (categorySelected.deliveryTypeArrivalDate) {
            current = new Date(firstArrivalDate);
        } else {
            if (categorySelected.deliveryTypeToday && categorySelected.deliveryTypeTomorrow) {
                if (deliveryType === "tomorrow") { // IF deliveryType = today. Is current day
                    current.setDate(current.getDate() + 1);
                }
            } else if (!categorySelected.deliveryTypeToday && categorySelected.deliveryTypeTomorrow) { // IF today = true && tomorrow = false. Is current day
                current.setDate(current.getDate() + 1);
            }
        }
        const deliveryDate = new Date(current.getFullYear(), current.getMonth(), current.getDate(), hours, minutes);

        const itemList = [];
        cart.forEach(curCart => {
            if (curCart.quantity > 0) {
                itemList.push({
                    itemId: curCart.id,
                    quantity: curCart.quantity
                })
            }
        });

        const guestOrderData = {
            categoryId: categoryId,
            deliveryDate: deliveryDate,
            reservationRoomId: reservationRoomId,
            roomTaxRate: roomTaxRate,
            salesTaxRate: salesTaxRate,
            itemList: itemList
        };

        const response = await axios.post("/ws/guestshopapi/createGuestOrder", guestOrderData);
        if(!!response && response.status === 200){
            this.setState({orderComplete: true})
        } else {
            this.setState({errorOnSave: true})
        }
    };

    deliveryTypeChange = (categorySelected) => this.setState( ...buildHoursList(this.state.categorySelected, categorySelected) );

    renderMakeAnOrder = () => {
        const {
            categorySelected, itemList, cart, instructions, isReservationCheckedIn, daySelection,
            showToday, showTomorrow, hoursValues, minutesValues, reservationRoomList, subTotal,
            total, tax, deliveryDay,
        } = this.state;

        return <div id="items" className="items">
            <h2>Make an Order</h2>
            <div className="menu-description"> {!!categorySelected && categorySelected.menuDescription} </div>

            <div className="items-content">
                {!!itemList && itemList.map((item, index) => {
                    return <div className={"item"} key={index}>
                        <div><span>{item.name} - ${item.price}</span></div>
                        {isAvailable(categorySelected) &&
                        <div className="buttons-content">
                            <button className="remove-item optionButton" onClick={() => this.removeItemFromCart(index)}>-</button>
                            <span className="item-quantity" id={index}>{cart[index].quantity}</span>
                            <button className="add-item optionButton" onClick={() => this.addItemToCart(index)}>+</button>
                        </div>
                        }
                    </div>
                })}
            </div>

            {isAvailable(categorySelected) &&
            <div>
                <div className="instructions"> {instructions} </div>
                <div className="total-content"> Select a Delivery Time</div>
                {!!isReservationCheckedIn && !!daySelection ? (
                    <div className={"total-content"}>
                        {!!showToday && <div>
                            <input type="radio" onChange={() => this.deliveryTypeChange(categorySelected)}
                                   id="today" value="today"/> Today
                        </div>}
                        {!!showTomorrow && <div>
                            <input type="radio" onChange={() => this.deliveryTypeChange(categorySelected)}
                                   id="tomorrow" value="tomorrow"/> Tomorrow
                        </div>
                        }
                    </div>
                ) : (
                    <div className="total-content"> {deliveryDay} </div>
                )}

                <div className="total-content">
                    <select onChange={(e) => this.setState({hours: e.target.value})}>
                        {hoursValues.map(
                            (option, index) => <option key={index} value={option.hour}>
                                {option.string}
                            </option>
                        )}
                    </select>

                    <select onChange={(e) => this.setState({minutes: e.target.value})}>
                        {minutesValues.map((option, index) => <option key={index} value={option}>
                            {this.pad(option, 2)}
                        </option>
                        )}
                    </select>
                </div>

                {reservationRoomList !== null && reservationRoomList.length > 1 &&
                <div className="total-content">
                    Room:
                    <select>
                        {reservationRoomList.map((reservationRoom, index) =>
                            <option key={index} value={reservationRoom.reservationRoomId}>
                                {reservationRoom.roomNumber}
                            </option>
                        )}
                    </select>
                </div>
                }

                <div className="total-content">Sub Total: ${toFixed(subTotal, 2)}</div>
                <div className="total-content">Tax: ${toFixed(tax, 2)}</div>
                <div className="total-content">Order Total: ${toFixed(total, 2)}</div>
                <div className="submit-content">
                    <button onClick={this.saveGuestOrder} className="submit optionButton">SUBMIT ORDER</button>
                </div>
            </div>
            }
        </div>
    };

    renderOrderSubmitted = () => {
        return <React.Fragment>
            <h2>Order Submitted!</h2>
            <div className="submit-content">
                <button onClick={this.returnToShop} className="submit optionButton">Return to Shop</button>
            </div>
        </React.Fragment>
    };

    render() {
        const {errorOnSave, orderComplete} = this.state;
        return (
            <React.Fragment>
                <button className="back optionButton" onClick={this.returnToShop}>Back</button>

                <div className="content">
                    { !!errorOnSave && <div className="error">
                        An error occurred. Please try again later.
                    </div>}

                    {!orderComplete ? (
                        this.renderMakeAnOrder()
                    ):(
                        this.renderOrderSubmitted()
                    )}
                </div>
            </React.Fragment>
        )
    }
}

export default MakeAnOrder;
